import '../../../../Assets/app.base';
import './Index.scss';

// Components
//import '../../../../Components/SocialShare';
import '../../../../Components/Navbar';
import FilterBarComponent from '../../../../Components/FilterBar';
import '../../../../Components/InfiniteScrollView';
import '../../../../Components/NoResultsWarning';
import initEventListComponent from '../../../../Components/EventList';
import '../../../../Components/VideoEmbed';

// This will run when DOM is loaded
function init() {
    const filterBar = new FilterBarComponent()

    $('#filter-btn').click((e) => {
        filterBar.toggle()
    })
}

$(function () {
    init()
})
